<template>
  <div class="jh-container">
    <div class="jh-ui-header">
      <h1>OB관리</h1>
      <div class="is-right">
      </div>
    </div>
      <!--조회-->
    <div class="jh-search-form">
      <table>
        <colgroup>
          <col width="55px">
          <col width="250px">
          <col width="70px">
          <col width="120px"> 
          <col>
        </colgroup>
        <tr>
          <th><label>등록일자</label></th>
          <td>
            <div class="jh-cols">
              <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                    maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                </template>
                <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
              </v-menu>
              <span class="jh-unit">~</span>                       
              <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                    maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                </template>
                <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
              </v-menu>
            </div>
          </td>
          <th><label>유형</label></th>
          <td>
            <v-select 
              class="jh-form" 
              :items="obTypeList"
              v-model="OBTYPE"
            >
            </v-select>
          </td>
          <td class="has-search"><v-btn class="jh-btn is-search" @click="searchList">조회</v-btn></td>
        </tr>
      </table>
    </div>
      <!--//조회-->

    <div class="jh-form-wrap is-pb-5">
      <div class="jh-ui-header">
        <h2>아웃바운드 리스트</h2>
        <div class="is-right">
          <v-btn class="jh-btn is-del" @click="deleteAll">삭제</v-btn>
        </div>
      </div>            
      <data-tables
        ref="dataTable"
        dataTableClass="has-control"
        :data-table-options="dataTableOptionsEvent"
        @click:row="onClickRowEvent"
        v-model="selectedRows"
      >
      </data-tables>
      
      <div class="jh-ui-header">
        <div class="is-left">
          <label class="jh-label" >등록일자 : </label>
          <input type="text" class="jh-form is-trns is-bold is-txt-blue is-p-0" style="width:80px" readonly v-model="REG_DTTM">
          <label class="jh-label">OB명</label>
          <input type="text" class="jh-form is-col-fix" style="width: 400px" v-model="OB_EVNT">

          <v-file-input
            ref="excelRef"
            v-model="fileValue"
            v-show="false"
            @change="excelUploadRun"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            counter
            label="엑셀파일을 선택하세요"
            :show-size="1000"
          />
          <v-btn class="jh-btn is-border-blue" @click="excelUpload">엑셀업로드</v-btn>
          <v-btn class="jh-btn is-main" v-show="!OB_Status" @click="newSave">신규등록</v-btn>
          <v-btn class="jh-btn is-main" v-show="OB_Status" :loading="isLoading" @click="eventSave">저장</v-btn>
          <div class="jh-info-txt is-red is-ml-10">파일명 예시) 1_권리명_종목명_권리기준일</div>
        </div>   

        <div class="is-right">
          <v-btn class="jh-btn is-del" :loading="isLoading" @click="this.delete">삭제</v-btn>
          <span class="jh-split"></span>
          <v-btn class="jh-btn is-light" @click="GardeLayer = !GardeLayer">고객등급표</v-btn>
          <v-btn class="jh-btn is-light" @click="excelDownload('OBList', obTypeName + ' OBList', obTypeName + ' OBList')">엑셀다운로드</v-btn>
          <transition name="slide-down">					
            <div class="jh-drop-layer" style="top: 0; right: 0;width:200px" v-show="GardeLayer">
              <div class="jh-drop-layer-header">
                <h2>고객등급표</h2>
                <div class="is-right">
                  <v-btn class="jh-btn is-icon" @click="GardeLayer = !GardeLayer"><i class="jh-icon-close"></i></v-btn>
                </div>
              </div>
              <div class="jh-drop-layer-body">
                <table class="jh-tbl-detail">
                  <colgroup>
                    <col width="80px">
                    <col>
                  </colgroup>
                  <thead>
                    <tr>
                      <th><label>고객등급코드</label></th>
                      <th><label>고객등급</label></th>
                    </tr>
                  </thead>
                  <tbody>        
                    <tr>
                      <th class="is-txt-center"><label>10</label></th>
                      <td><span class="is-value">신규</span></td>
                    </tr>
                    <tr>
                      <th class="is-txt-center"><label>11</label></th>
                      <td><span class="is-value">일반(BEP미만)</span></td>
                    </tr>
                    <tr>
                      <th class="is-txt-center"><label>12</label></th>
                      <td><span class="is-value">일반(BEP이상)</span></td>
                    </tr>
                    <tr>
                      <th class="is-txt-center"><label>13</label></th>
                      <td><span class="is-value">준우수</span></td>
                    </tr>
                    <tr>
                      <th class="is-txt-center"><label>14</label></th>
                      <td><span class="is-value">준우수(소액미달)</span></td>
                    </tr>
                    <tr>
                      <th class="is-txt-center"><label>15</label></th>
                      <td><span class="is-value">우수</span></td>
                    </tr>
                    <tr>
                      <th class="is-txt-center"><label>16</label></th>
                      <td><span class="is-value">최우수</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </transition>
        </div>
        
      </div>
      
      <!-- 유상청약 -->
      <data-tables
        ref="dataTableDetail"
        id="OBList"
        dataTableClass="has-control"
        :data-table-options="dataTableOptions01"
        @click:row="onClickRow"
        v-model="selectedDetailRows01"
        v-if="this.selectedOBTYPE == '01' || this.selectedOBTYPE == ''"
      >
        <template v-slot:header.CUST_GRD>
          <span>고객등급<br>코드</span>
        </template>
        <template v-slot:header.APLY_TYPE>
          <span>신청유무<br>코드</span>
        </template>
        <template v-slot:header.EXCS_ASGN_QNT>
          <span>초과<br>배정수량</span>
        </template>
        <template v-slot:header.TOT_APLCTN_AMT>
          <span>총 청약대금<br>배정+초과배정</span>
        </template>
        <template v-slot:header.ASGN_APLCTN_QNT>
          <span>배정<br>청약수량</span>
        </template>
      </data-tables>

      <!-- 반대의사 -->
      <data-tables
        ref="dataTableDetail"
        id="OBList"
        dataTableClass="has-control"
        :data-table-options="dataTableOptions02"
        @click:row="onClickRow"
        v-model="selectedDetailRows02"
        v-if="selectedOBTYPE == '02'"
      >
        <template v-slot:header.CUST_GRD>
          <span>고객등급<br>코드</span>
        </template>
        <template v-slot:header.APLY_TYPE>
          <span>신청유무<br>코드</span>
        </template>
        <template v-slot:header.OPP_POSL_QNT>
          <span>반대의사<br>가능수량</span>
        </template>
        <template v-slot:header.OPP_RCPT_QNT>
          <span>반대의사<br>접수수량</span>
        </template>
      </data-tables>

      <!-- 매수청구 -->
      <data-tables
        ref="dataTableDetail"
        id="OBList"
        dataTableClass="has-control"
        :data-table-options="dataTableOptions03"
        @click:row="onClickRow"
        v-model="selectedDetailRows03"
        v-if="selectedOBTYPE == '03'"
      >
        <template v-slot:header.CUST_GRD>
          <span>고객등급<br>코드</span>
        </template>
        <template v-slot:header.APLY_TYPE>
          <span>신청유무<br>코드</span>
        </template>
        <template v-slot:header.BUYN_POSL_QNT>
          <span>매수청구<br>가능수량</span>
        </template>
        <template v-slot:header.BUYN_QNT>
          <span>매수청구<br>수량</span>
        </template>
      </data-tables>

      <!-- TM -->
      <data-tables
        ref="dataTableDetail"
        id="OBList"
        dataTableClass="has-control"
        :data-table-options="dataTableOptions04"
        @click:row="onClickRow"
        v-model="selectedDetailRows04"
        v-if="selectedOBTYPE == '04'"
      >
        <template v-slot:header.CUST_GRD>
          <span>고객등급<br>코드</span>
        </template>
        <template v-slot:header.MNGR_ID>
          <span>담당자<br>사번</span>
        </template>
      </data-tables>

    </div>
  </div>
</template>
  
<script>
import XLSX from "xlsx";
import moment from "moment";
import { mapGetters } from "vuex";
import api from "@/store/apiUtil.js";
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";
import DataTables from '../../components/DataTables.vue';

export default {
  name: "MENU_E010303", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],

  components: {
    dataTables
  },

  data() {
    return {
      menuStartDate: false,
      menuEndDate  : false,
      OB_Status    : false,
      isLoading    : false,
      GardeLayer    : false,

      commonCodeItems      : [],
      gridOBList           : [],
      selectedRows         : [],
      selectedDetailRows01 : [],
      selectedDetailRows02 : [],
      selectedDetailRows03 : [],
      selectedDetailRows04 : [],

      OBTYPE          : null,
      OB_EVNT         : null,
      fileValue       : null,
      obTypeName      : null,
      selectedOBTYPE  : null,
      selectedEventId : null,
    
      REG_DTTM : [moment().format("YYYY-MM-DD")],

      from : moment().subtract(30, "days").format("YYYY-MM-DD"),
      to   : moment().format("YYYY-MM-DD"),
      dates: [
        moment().subtract(30, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],

      userInfo: {
        USER_ID     : this.$store.getters["userStore/GE_USER_ROLE"].userId,
        USER_NM     : this.$store.getters["userStore/GE_USER_ROLE"].userName,
        USER_ATTR_A : this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_A,
        USER_ATTR_B : this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_B,
        USER_ATTR_C : this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_C,
      },
      
      headersEvent: [
        { text: 'No', value: 'index', align: 'center', width: '60px', sortable:false},
        { text: 'OB명', value: 'OB_EVNT', },
        { text: '등록일', value: 'REG_DTTM', align: 'center', width: '200px', },
        { text: '등록자사번', value: 'REG_ID', align: 'center', width: '200px', },
        { text: '등록자', value: 'REG_NM', align: 'center', width: '200px', },
        { text: '업로드건수', value: 'UPLOAD_CNT', align: 'center', width: '200px', },
      ],

      itemsEvent: [],

      dataTableOptionsEvent: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '200px',
        items: undefined,
        itemKey: 'index',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        singleSelect: true,
        disablePagination: true,
      }, 

      headers: {
        // 유상청약
        "01": [
          { text: 'No', value: 'index', align: 'center', width: '30px', sortable:false},
          { text: '종목명', value: 'OB_EVNT_DETL', width: '150px', },
          { text: '고객등급코드', value: 'CUST_GRD', align: 'center', width: '70px', },
          { text: '고객등급', value: 'CUST_GRD_NM', align: 'center', width: '70px', },
          { text: '고객번호', value: 'CUST_NO', align: 'center', width: '70px', },
          { text: '계좌번호', value: 'CUST_ACTNO', align: 'center', width: '100px', },
          { text: '고객명', value: 'CUST_NM', align: 'center', width: '70px', },
          { text: '신청유무코드', value: 'APLY_TYPE', align: 'center', width: '70px',},
          { text: '신청유무', value: 'APLY_TYPE_NM', align: 'center', width: '70px',},
          { text: '1차결과', value: 'NTFCTN_1st_NM', align: 'center', width: '70px', },
          { text: 'N차결과', value: 'NTFCTN_Nth_NM', align: 'center', width: '70px', },
          { text: '통보처리일시', value: 'NTFCTN_DTTM', align: 'center', width: '90px', },
          { text: '담당자 사번', value: 'MNGR_ID', align: 'center', width: '60px', },
          { text: '담당자', value: 'MNGR_NM', align: 'center', width: '70px', },
          { text: '통보직원', value: 'NTFCTN_NM', align: 'center', width: '100px', },
          { text: '비고', value: 'REM', align: 'center', width: '150px', sortable: false},
          { text: '업로드일자', value: 'UPLOAD_DTTM', align: 'center', width: '85px', },
          { text: '소유수량', value: 'OWN_QNT', align: 'right', width: '75px', },
          { text: '배정수량', value: 'ASGN_QNT', align: 'right', width: '75px', },
          { text: '청약대금(배정수량)', value: 'APLCTN_AMT', align: 'right', width: '75px', },
          { text: '초과배정수량', value: 'EXCS_ASGN_QNT', align: 'right', width: '75px', },
          { text: '총 청약대금배정+초과배정', value: 'TOT_APLCTN_AMT', align: 'right', width: '105px', },
          { text: '배정청약수량', value: 'ASGN_APLCTN_QNT', align: 'right', width: '75px',},
          { text: '초과배정 청약수량', value: 'EXCS_ASGN_APLCTN_QNT', align: 'right', width: '75px',},
        ],

        // 반대의사
        "02": [
          { text: 'No', value: 'index', align: 'center', width: '30px', sortable:false},
          { text: '종목명', value: 'OB_EVNT_DETL', width: '150px', },
          { text: '고객등급코드', value: 'CUST_GRD', align: 'center', width: '70px', },
          { text: '고객등급', value: 'CUST_GRD_NM', align: 'center', width: '70px', },
          { text: '고객번호', value: 'CUST_NO', align: 'center', width: '70px', },
          { text: '계좌번호', value: 'CUST_ACTNO', align: 'center', width: '100px', },
          { text: '고객명', value: 'CUST_NM', align: 'center', width: '70px', },
          { text: '신청유무코드', value: 'APLY_TYPE', align: 'center', width: '70px',},
          { text: '신청유무', value: 'APLY_TYPE_NM', align: 'center', width: '70px',},
          { text: '1차결과', value: 'NTFCTN_1st_NM', align: 'center', width: '70px', },
          { text: 'N차결과', value: 'NTFCTN_Nth_NM', align: 'center', width: '70px', },
          { text: '통보처리일시', value: 'NTFCTN_DTTM', align: 'center', width: '90px', },
          { text: '담당자 사번', value: 'MNGR_ID', align: 'center', width: '60px', },
          { text: '담당자', value: 'MNGR_NM', align: 'center', width: '70px', },
          { text: '통보직원', value: 'NTFCTN_NM', align: 'center', width: '100px', },
          { text: '비고', value: 'REM', align: 'center', width: '150px', sortable: false},
          { text: '업로드일자', value: 'UPLOAD_DTTM', align: 'center', width: '85px', },
          { text: '소유수량', value: 'OWN_QNT', align: 'right', width: '75px', },
          { text: '반대의사가능수량', value: 'OPP_POSL_QNT', align: 'right', width: '75px', },
          { text: '반대의사접수수량', value: 'OPP_RCPT_QNT', align: 'right', width: '75px', },
        ],

        // 매수청구
        "03": [
          { text: 'No', value: 'index', align: 'center', width: '30px', sortable:false},
          { text: '종목명', value: 'OB_EVNT_DETL', width: '150px', },
          { text: '고객등급코드', value: 'CUST_GRD', align: 'center', width: '70px', },
          { text: '고객등급', value: 'CUST_GRD_NM', align: 'center', width: '70px', },
          { text: '고객번호', value: 'CUST_NO', align: 'center', width: '70px', },
          { text: '계좌번호', value: 'CUST_ACTNO', align: 'center', width: '100px', },
          { text: '고객명', value: 'CUST_NM', align: 'center', width: '70px', },
          { text: '신청유무코드', value: 'APLY_TYPE', align: 'center', width: '70px',},
          { text: '신청유무', value: 'APLY_TYPE_NM', align: 'center', width: '70px',},
          { text: '1차결과', value: 'NTFCTN_1st_NM', align: 'center', width: '70px', },
          { text: 'N차결과', value: 'NTFCTN_Nth_NM', align: 'center', width: '70px', },
          { text: '통보처리일시', value: 'NTFCTN_DTTM', align: 'center', width: '90px', },
          { text: '담당자 사번', value: 'MNGR_ID', align: 'center', width: '60px', },
          { text: '담당자', value: 'MNGR_NM', align: 'center', width: '70px', },
          { text: '통보직원', value: 'NTFCTN_NM', align: 'center', width: '100px', },
          { text: '비고', value: 'REM', align: 'center', width: '150px', sortable: false},
          { text: '업로드일자', value: 'UPLOAD_DTTM', align: 'center', width: '85px', },
          { text: '반대통보 신청수량', value: 'OPP_NTFCTN_QNT', align: 'right', width: '70px', },
          { text: '매수청구가능수량', value: 'BUYN_POSL_QNT', align: 'right', width: '75px', },
          { text: '매수청구수량', value: 'BUYN_QNT', align: 'right', width: '75px', },
        ],

        // TM
        "04": [
          { text: 'No', value: 'index', align: 'center', width: '30px', sortable:false},
          { text: 'TM명', value: 'OB_EVNT_DETL', width: '150px', },
          { text: '고객등급코드', value: 'CUST_GRD', align: 'center', width: '70px', },
          { text: '고객등급', value: 'CUST_GRD_NM', align: 'center', width: '70px', },
          { text: '고객번호', value: 'CUST_NO', align: 'center', width: '70px', },
          { text: '계좌번호', value: 'CUST_ACTNO', align: 'center', width: '100px', },
          { text: '고객명', value: 'CUST_NM', align: 'center', width: '70px', },
          { text: '관리점', value: 'MNG_BRAN', align: 'center', width: '100px',},
          { text: '1차결과', value: 'NTFCTN_1st_NM', align: 'center', width: '70px', },
          { text: 'N차결과', value: 'NTFCTN_Nth_NM', align: 'center', width: '70px', },
          { text: '통보처리일시', value: 'NTFCTN_DTTM', align: 'center', width: '90px', },
          { text: '담당자 사번', value: 'MNGR_ID', align: 'center', width: '60px', },
          { text: '담당자', value: 'MNGR_NM', align: 'center', width: '70px', },
          { text: '통보직원', value: 'NTFCTN_NM', align: 'center', width: '100px', },
          { text: '비고', value: 'REM', align: 'center', width: '150px', sortable: false},
          { text: '업로드일자', value: 'UPLOAD_DTTM', align: 'center', width: '85px', },
        ],
      },

      items: [],

      // 유상청약
      dataTableOptions01: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '350px',
        items: [],
        itemKey: 'index',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        showSelect: true,
        //singleSelect: true,
        disablePagination: true,
      },

      // 반대의사
      dataTableOptions02: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '350px',
        items: [],
        itemKey: 'index',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        showSelect: true,
        disablePagination: true,
      }, 

      // 매수청구
      dataTableOptions03: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '350px',
        items: [],
        itemKey: 'index',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        showSelect: true,
        disablePagination: true,
      }, 

      // TM
      dataTableOptions04: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '350px',
        items: [],
        itemKey: 'index',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        showSelect: true,
        disablePagination: true,
      },  

      alertMsg: {
        responseError: "시스템에 오류가 발생하였습니다.",
      },
    }
  },
  computed: {
    obTypeList: function() {
      return this.mixin_common_code_get(this.commonCodeItems, 'OB00');
    },
    dataTableInstance: function() {
      return this.$refs.dataTable;
    },
    dataTableDetailInstance: function() {
      return this.$refs.dataTableDetail;
    },
  },

  methods: {
    // 셀렉트박스 공통코드 조회
    async initMain(){
      this.fileValue = null

      let pArr = ['OB00', 'CRS000', 'DS38', 'OB02']
      this.commonCodeItems = await this.mixin_common_code_get_all(pArr)
      
      // 화면로드시 OB유형 코드 초기값 01: 유상청약
      // this.selectedOBTYPE = this.OBTYPE = "01";
      // 화면로드시 OB유형 코드 초기값 - 빈값 (2023.01.16)
      this.selectedOBTYPE = this.OBTYPE = '';
      this.changeObType();
      
      this.obTypeList.unshift({ "text": "선택", "value": "" });
    },

    // 조회 버튼
    searchList () {
      if (this.OBTYPE != '') {
        this.fileValue = null;
        this.OB_Status = false;
        this.OB_EVNT = "";
        this.selectedRows = [];
        this.selectedEventId = "";
        this.dataTableOptionsEvent.items = [];
        this[`dataTableOptions${this.OBTYPE}`].items = [];
  
        this.selectedOBTYPE = this.OBTYPE;
        this.changeObType();
        this.setDataTableParams();
        this.getGridList();
      } else {
        this.common_alert('유형을 먼저 선택해주세요.', 'error');
      }
    },

    // OB 마스터 조회
    setDataTableParams: function() {
      this.requestData = {
        headers: {},
        sendData: {},
      };

      this.requestData.headers["URL"]      = "/api/phone/ob/manage/masterOb";
      this.requestData.headers["SERVICE"]  = "phone.ob.manage";
      this.requestData.headers["METHOD"]   = "masterOb";
      this.requestData.headers["ASYNC"]    =  false;
      this.requestData.headers["TYPE"]     = "BIZ_SERVICE";

      this.requestData.sendData.FROM       = this.dates[0];                       // 기간조회 - FROM: 시작일 
      this.requestData.sendData.TO         = this.dates[1];                       // 기간조회 - TO  : 종료일

      this.requestData.sendData["REG_ID"]  = this.userInfo.USER_ID;               // 등록자ID
      this.requestData.sendData["OB_TYPE"] = this.OBTYPE;                         // OB유형 - 01: 유상청약 / 02: 반대의사 / 03: 매수청구 / 04: TM
    },

    getGridList(loadOptions) {
      this.common_postCall(this.requestData).then(response => {
        let resHeader = response.HEADER;
        let resData = response.DATA;
        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG == true) {
          this.common_alert(this.alertMsg.responseError, "error");
          return false;
        }
        // 날짜 세팅 -> 시간까지
        // _.each(resData, (item) => {
        //   item.REG_DTTM = item.REG_DTTM.substr(0, 19);
        // });

        // 날짜 세팅 -> 날짜까지
        _.each(resData, (item) => {
          item.REG_DTTM = item.REG_DTTM.substr(0, 10);
        });

        // 순번 세팅
        this.dataTableOptionsEvent.items = resData.map(
          (item, index) => ({
          ...item,
          index: index + 1
        }));
      });
    },

    // OB 디테일 조회
    getGridDetailList(item) {
      var requestData = {
        headers: {},
        sendData: {},
      };

      requestData.headers["URL"]      = "/api/phone/ob/manage/detailOb";
      requestData.headers["SERVICE"]  = "phone.ob.manage";
      requestData.headers["METHOD"]   = "detailOb";
      requestData.headers["ASYNC"]    =  false;
      requestData.headers["TYPE"]     = "BIZ_SERVICE";

      requestData.sendData["ID"]      = item.ID  

      this.common_postCall(requestData).then(response => {
        let resHeader = response.HEADER;
        let resData = response.DATA;
        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG == true) {
          this.common_alert(this.alertMsg.responseError, "error");
          return false;
        }
        // 날짜 세팅 -> 시간까지
        _.each(resData, (item) => {
          item.NTFCTN_DTTM = item.NTFCTN_DTTM.substr(0, 19); // 통보처리일시
        });

        // 날짜 세팅 -> 날짜까지
        //  _.each(resData, (item) => {
        //   item.REG_DTTM = item.REG_DTTM.substr(0, 10);
        // });

        this.OB_Status = true;
        this.OB_EVNT  = item.OB_EVNT;                     // OB명
        this.REG_DTTM = item.REG_DTTM.substr(0, 10);      // 등록일자

        // 리스트 디테일 조회시에는 싱글선택
        this[`dataTableOptions${this.OBTYPE}`].singleSelect = true;

        this[`dataTableOptions${this.OBTYPE}`].items = resData.map(
          (item, index) => ({
          ...item,
          index: index + 1
        }));
      });
    },

    // 저장 버튼
    eventSave(){
      if(this.OB_EVNT == null || this.OB_EVNT == ""){
        this.common_alert("OB명을 입력해 주세요.", "error");
      } else {
        this.common_confirm('저장하시겠습니까?', this.eventSaveOB, null, null, null, 'chk');
      }
    },

    // 저장 (마스터, 상세 - 이벤트(종목)명 수정)
    eventSaveOB(){
      this.isLoading = true;

      // header 세팅
      let requestData = {
        headers: {
          ASYNC  : false,
          METHOD : "updateOb",
          SERVICE: "phone.ob.manage",
          TYPE   : "BIZ_SERVICE",
          URL    : "/api/phone/ob/manage/updateOb"
        },
        sendData: {},
      };

      // sendData 세팅
      requestData.sendData.USER_ID = this.userInfo.USER_ID;           // 등록자ID
      requestData.sendData.OB_EVNT = this.OB_EVNT;                    // OB명
      requestData.sendData.ID      = this.selectedEventId;            // 선택된 리스트의 key: ID

      this.common_postCall(requestData).then(res => {
        if (this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
            this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
            return false;
        }
        this.common_alert("정상 처리되었습니다.", "done");
        //this.searchList();
        this.setDataTableParams();
        this.getGridList();
      }).finally(() => {
          this.isLoading = false;
      });
    },

    // 신규등록 버튼
    newSave(){
      if(this.OB_EVNT == null || this.OB_EVNT == ""){
        this.common_alert("OB명을 입력해 주세요.", "error");
      } 
      else if(this[`dataTableOptions${this.OBTYPE}`].items == null || this[`dataTableOptions${this.OBTYPE}`].items == ""){
        this.common_alert("데이터가 없습니다.</br>엑셀업로드를 해주세요.", "error");
      }
      else {
        this.common_confirm('신규등록 하시겠습니까?', this.newSaveOB, null, null, null, 'chk');
      }
    },

    // 신규등록
    newSaveOB(){
      this.isLoading = true;

      // header 세팅
      let requestData = {
        headers: {
          ASYNC  : false,
          METHOD : "insertOb",
          SERVICE: "phone.ob.manage",
          TYPE   : "BIZ_SERVICE",
          URL    : "/api/phone/ob/manage/insertOb"
        },
        sendData: {},
      };

      // sendData 세팅
      requestData.sendData.USER_ID = this.userInfo.USER_ID;
      requestData.sendData.OB_EVNT = this.OB_EVNT;
      requestData.sendData.OB_TYPE = this.OBTYPE;
      requestData.sendData.OB_List = this[`dataTableOptions${this.OBTYPE}`].items;

      this.common_postCall(requestData).then(res => {
        if (this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
          this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
          return false;
        }
        this.common_alert("정상 처리되었습니다.", "done");
        //this.searchList();
        this.setDataTableParams();
        this.getGridList();
        this.getGridDetailList(res.DATA[0]);
        this.OB_Status = true;
        this.fileValue = null;
      }).finally(() => {
          this.isLoading = false;
      });
    },

    // 삭제
    delete: function() {
      if(this[`selectedDetailRows${this.OBTYPE}`] == "" || this[`selectedDetailRows${this.OBTYPE}`] == null){
        this.common_alert("선택된 항목이 없습니다.", "error");
      }
      else if(this[`selectedDetailRows${this.OBTYPE}`][0].ID == null || this[`selectedDetailRows${this.OBTYPE}`][0].ID == ""){
        this.common_confirm('엑셀  데이터가 삭제됩니다.</br>삭제하시겠습니까?', this.deleteRow, null, null, null, 'chk');
      }
      else{
        this.common_confirm('서버  데이터가 삭제됩니다.</br>삭제하시겠습니까?', this.deleteOb, null, null, null, 'chk');
      }
    },
    // 컬럼에 데이터 있는지 판단하는 함수
    // function() {
    //   let result = false;

    //   for (let v of this[`selectedDetailRows${this.OBTYPE}`]) {
    //     if (v["1차결과"]) return true;
    //   }

    //   return result;
    // }

    // 삭제: 조회된 리스트 삭제 (DB삭제)
    deleteOb: function() {
      this.isLoading = true;

      let OB_DetailList = [];

      // 그리드에서 선택된 로우의 ID값만 가져온다.
      for(let i in this[`selectedDetailRows${this.OBTYPE}`]){
        let obj = {
          ID: this[`selectedDetailRows${this.OBTYPE}`][i].ID
        };
        OB_DetailList.push(obj);
      }

      // header 세팅
      let requestData = {
        headers: {
          ASYNC: false,
          METHOD: "deleteOb",
          SERVICE: "phone.ob.manage",
          TYPE: "BIZ_SERVICE",
          URL: "/api/phone/ob/manage/deleteOb"
        },
        // sendData 세팅
        sendData: { OB_DetailList : OB_DetailList},
      };
      
      this.common_postCall(requestData).then(res => {
        if (this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
          this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
          return false;
        } 
          else if (res.DATA[0].Exist) {
          this.common_alert(`업무처리된 건은 삭제할 수 없습니다.`, "error");
          return false;
        } 
        this.common_alert("정상 처리되었습니다.", "done");  

        this.fileValue = null;
        this.setDataTableParams();
        this.getGridList();
        this.getGridDetailList(this.selectedRows[0]);
        this[`selectedDetailRows${this.OBTYPE}`] = [];
      }).finally(() => {
          this.isLoading = false;
      });
    },

    // 삭제: 엑셀업로드 로우 삭제
    deleteRow: function() {
      for (let i in this[`selectedDetailRows${this.OBTYPE}`]) {
        let items = this[`dataTableOptions${this.OBTYPE}`].items;
        items.splice(items.indexOf(this[`selectedDetailRows${this.OBTYPE}`][i]), 1);
      }
      this.fileValue = null;
      this[`selectedDetailRows${this.OBTYPE}`] = [];
    },

    deleteAll() {
      this.common_confirm('선택한 데이터가 삭제됩니다.</br>삭제하시겠습니까?', this.deleteObMaster, null, null, null, 'chk');
    },

    deleteObMaster() {
      // header 세팅
      let requestData = {
        headers: {
          ASYNC: false,
          METHOD: "deleteOb",
          SERVICE: "phone.ob.manage",
          TYPE: "BIZ_SERVICE",
          URL: "/api/phone/ob/manage/deleteObMaster"
        },
        // sendData 세팅
        sendData: {},
      };

      requestData.sendData.ID = this.selectedRows[0].ID;
      
      this.common_postCall(requestData).then(res => {
        if (this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
          this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
          return false;
        } else if (res.DATA[0].Exist) {
          this.common_alert(`업무처리된 건은 삭제할 수 없습니다.`, "error");
          return false;
        } else {
          this.common_alert("정상 처리되었습니다.", "done");
        }

        this.fileValue = null;
        this.setDataTableParams();
        this.getGridList();
        this[`dataTableOptions${this.OBTYPE}`].items = [];
      }).finally(() => {
          this.isLoading = false;
      });
    },

    // 엑셀업로드
    excelUpload() {
      if (this.OBTYPE != '') {
        let that = this;
        this.dataList = this[`dataTableOptions${this.OBTYPE}`].items;
  
        // 엑셀업로드시 디테일 리스트 있는지 판단
        if(this.dataList.length > 1){
          this.common_confirm('데이터가 새로 조회됩니다.</br>엑셀업로드를 하시겠습니까?', () => { this.$refs.excelRef.$refs.input.click(); }, null, null, null, 'chk');
        }
        else{
          this.$refs.excelRef.$refs.input.click();
        }
      } else {
        this.common_alert('유형을 먼저 선택해주세요.', 'error');
      }
    },

    // 엑셀업로드
    excelUploadRun(file) {
      const fileName = file.name
      const reader = new FileReader()

      reader.onload = (e) => {
        var buffer = reader.result
        var data = new Uint8Array(buffer)
        var arr = new Array();
        for (var i = 0; i != data.length; ++i)
          arr[i] = String.fromCharCode(data[i])
        var bstr = arr.join("")
        var workbook = XLSX.read(bstr, {
          type: "binary",
          cellDates: true,
        })
        var first_sheet_name = workbook.SheetNames[0]
        var worksheet = workbook.Sheets[first_sheet_name]
        var getData = XLSX.utils.sheet_to_json(worksheet, { header:2, raw:false, blankRows:false, defval:'' })

        // 필드 초기화
        this.OB_Status = false;
        this.OB_EVNT = "";
        this.selectedEventId = "";
        this.selectedRows = [];
        this[`dataTableOptions${this.OBTYPE}`].items = [];
        this[`selectedDetailRows${this.OBTYPE}`] = [];
        this.REG_DTTM = [moment().format("YYYY-MM-DD")]; 

        // 엑셀업로드시에는 멀티선택
        this[`dataTableOptions${this.OBTYPE}`].singleSelect = false;
        
        if(getData.length > 0) {
          this[`dataTableOptions${this.OBTYPE}`].items = getData.map((item, index) => {
            let map = {}
            this.headers[this.OBTYPE].forEach(header => {
              let keys = Object.keys(item);
              
              // 그리드 헤더
              let headertext = header.text.replaceAll(" ", "");

              let findedKey = _.find(keys, key => { 
              let headerkey = key.replaceAll(" ", "");
                  headerkey = headerkey.replace(/\r\n/g, "");
                  headerkey = headerkey.replace(/\n/g, "");

                return headerkey == headertext;
              });

              if (findedKey) {
                map[header.value] = item[findedKey];
              }
            });
            map['index'] = index + 1;
            
            return map;
          });
        }
      }
      reader.readAsArrayBuffer(file)
      this.common_alert('업로드가 정상처리되었습니다.<br/>OB명 입력 후 신규등록을 클릭해주세요.', 'done');
    },

    // 엑셀다운로드
    excelDownload(table_id, file_name, sheet_name) {

      console.log(file_name + "/" + sheet_name);
      //number,date,time,string - n,d,t,s
      var exceltypeArr = {};


      if(file_name == "유상청약 OBList"){
          exceltypeArr[0] = "s";
          exceltypeArr[1] = "n";
          exceltypeArr[2] = "s";
          exceltypeArr[3] = "s";
          exceltypeArr[4] = "s";
          exceltypeArr[5] = "s";
          exceltypeArr[6] = "s";
          exceltypeArr[7] = "s";
          exceltypeArr[8] = "s";
          exceltypeArr[9] = "s";
          exceltypeArr[10] = "s";
          exceltypeArr[11] = "s";
          exceltypeArr[12] = "s";
          exceltypeArr[13] = "s";
          exceltypeArr[14] = "s";
          exceltypeArr[15] = "s";
          exceltypeArr[16] = "s";
          exceltypeArr[17] = "s";
          exceltypeArr[18] = "n";
          exceltypeArr[19] = "n";
          exceltypeArr[20] = "n";
          exceltypeArr[21] = "n";
          exceltypeArr[22] = "n";
          exceltypeArr[23] = "n";
          exceltypeArr[24] = "n";

      } else if(file_name == "반대의사 OBList"){
        exceltypeArr[0] = "s";
          exceltypeArr[1] = "n";
          exceltypeArr[2] = "s";
          exceltypeArr[3] = "s";
          exceltypeArr[4] = "s";
          exceltypeArr[5] = "s";
          exceltypeArr[6] = "s";
          exceltypeArr[7] = "s";
          exceltypeArr[8] = "s";
          exceltypeArr[9] = "s";
          exceltypeArr[10] = "s";
          exceltypeArr[11] = "s";
          exceltypeArr[12] = "s";
          exceltypeArr[13] = "s";
          exceltypeArr[14] = "s";
          exceltypeArr[15] = "s";
          exceltypeArr[16] = "s";
          exceltypeArr[17] = "s";
          exceltypeArr[18] = "n";
          exceltypeArr[19] = "n";
          exceltypeArr[20] = "n";

      }else if(file_name == "매수청구 OBList"){
          exceltypeArr[0] = "s";
          exceltypeArr[1] = "n";
          exceltypeArr[2] = "s";
          exceltypeArr[3] = "s";
          exceltypeArr[4] = "s";
          exceltypeArr[5] = "s";
          exceltypeArr[6] = "s";
          exceltypeArr[7] = "s";
          exceltypeArr[8] = "s";
          exceltypeArr[9] = "s";
          exceltypeArr[10] = "s";
          exceltypeArr[11] = "s";
          exceltypeArr[12] = "s";
          exceltypeArr[13] = "s";
          exceltypeArr[14] = "s";
          exceltypeArr[15] = "s";
          exceltypeArr[16] = "s";
          exceltypeArr[17] = "s";
          exceltypeArr[18] = "n";
          exceltypeArr[19] = "n";
          exceltypeArr[20] = "n";

      }else if(file_name == "TM OBList"){
        exceltypeArr[0] = "s";
        exceltypeArr[1] = "n";
        exceltypeArr[2] = "s";
        exceltypeArr[3] = "s";
        exceltypeArr[4] = "s";
        exceltypeArr[5] = "s";
        exceltypeArr[6] = "s";
        exceltypeArr[7] = "s";
        exceltypeArr[8] = "s";
        exceltypeArr[9] = "s";
        exceltypeArr[10] = "s";
        exceltypeArr[11] = "s";
        exceltypeArr[12] = "s";
        exceltypeArr[13] = "s";
        exceltypeArr[14] = "s";
        exceltypeArr[15] = "s";
        exceltypeArr[16] = "s";
      
      }


      this.table_id   = table_id
      this.file_name  = file_name
      this.sheet_name = sheet_name
      this.mixin_common_exportExcel(exceltypeArr);
    },

    fromOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
        // 종료일자보다 이후를 입력했으면 종료일자로 변경
        if (moment(this.from).isAfter(this.dates[1])) {
          this.$nextTick(() => {
            this.from = this.dates[1];
          });
        }

        this.$nextTick(() => {
          this.dates[0] = this.from;
          // datepick 다시 랜더링
          this.menuStartDate = false;
          this.menuStartDate = true;
        });
      }
    },

    changeDate(type) {
        if(type == 'start') {
            if (moment(this.dates[0]).isBefore(moment(this.dates[1]).subtract(365, 'days').format('YYYY-MM-DD'))) {
              this.common_alert('검색 기간은 최대 1년까지 선택 가능합니다.');
              const date = moment().subtract(30, "days").format("YYYY-MM-DD");
              this.dates[0] = date;
              this.from = date;
              return;
            } else {
                this.from = this.dates[0];
            }
        } else if (type == 'end') {
            if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(365, 'days').format('YYYY-MM-DD'))) {
              this.common_alert('검색 기간은 최대 1년까지 선택 가능합니다.');
              const date = moment().format("YYYY-MM-DD");
              this.dates[1] = date;
              this.to = date;
              return;
            } else {
              this.to = this.dates[1];
            }
        }
    },

    startDate(e) {
      this.from = e;
    },

    toOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
        // 시작일자보다 이전을 입력했으면 시작일자로 변경
        if (moment(this.to).isBefore(this.dates[0])) {
          this.$nextTick(() => {
            this.to = this.dates[0];
          });
        }

        this.$nextTick(() => {
          this.dates[1] = this.to;
          // datepick 다시 랜더링
          this.menuEndDate = false;
          this.menuEndDate = true;
        });
      }
    },

    endDate(e) {
      this.to = e;
    },

    // 마스터 리스트 클릭
    onClickRowEvent: function (item, row) {   
      if(this.selectedEventId === item.ID){
        row.select(false);

        // 로우 클릭 해제시 필드 초기화
        this.selectedEventId = "";
        this.OB_EVNT = "";  
        this.OB_Status = false;    
        this[`selectedDetailRows${this.OBTYPE}`] = [];
        this[`dataTableOptions${this.OBTYPE}`].items = [];
        this.REG_DTTM = [moment().format("YYYY-MM-DD")]; 

      }else{
        row.select(true);    

        if(this.selectedEventId == "" && this.mixin_isEmpty(this[`dataTableOptions${this.OBTYPE}`].items)){
          this.getGridDetailList(item);
        } 
        else if(this.selectedEventId == "" || this.selectedEventId == null){
          this.common_confirm('데이터가 새로 조회됩니다.', () => { this.getGridDetailList(item); }, null, this.noCheck, null, 'chk');
        } 
        else {
          this.getGridDetailList(item);
        }

        this.selectedEventId = item.ID;
        this[`selectedDetailRows${this.OBTYPE}`] = [];  
        this.fileValue = null;
      }
    },

    // 아니오 클릭시 초기화
    noCheck() {
      //this.OB_EVNT = "";
      this.selectedEventId = "";
      this.selectedRows = [];
    },

    // 상세 리스트 클릭
    onClickRow: function (item, row) { 
      // if(this.selectedId === item.index){
      //   row.select(false);
      //   this.selectedId = "";
      // }else{
      //   row.select(true);
      //   this.selectedId = item.index;
      // }
    },

    // OBTYPE 유형의 선택된 코드로 유형코드의 text 구하기
    changeObType: function() {
      for (let i = 0; i < this.obTypeList.length; i++) {
        let obType = this.obTypeList[i];
        if (obType.value == this.OBTYPE) {
          this.obTypeName = obType.text;
        }
      }
    },
    
  },
  mounted() {
    this.initMain();
  },
  created() { 
    this.dataTableOptionsEvent.headers = this.headersEvent;
    
    this.dataTableOptions01.headers = this.headers["01"];
    this.dataTableOptions02.headers = this.headers["02"];
    this.dataTableOptions03.headers = this.headers["03"];
    this.dataTableOptions04.headers = this.headers["04"];
  }
};
</script>
  
<style></style>  